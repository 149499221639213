@import './colors';

$menu-size: 260px;
$global-padding: 3.5rem;
$mobile-breakpoint: 1200px;
$media-mobile: "(orientation : portrait) and (max-width : #{$mobile-breakpoint - 1})";
$media-desktop: "(orientation : landscape), (min-width : #{$mobile-breakpoint})";

$font-color: #231F20;
$background-color: #E6E3E0;
$title-color: #361830;
$accent-color: #466983;
