@import 'configuration';

h1, h2, h3, h4, h5, h6 {
  font-family: 'Libre Baskerville', serif;
  color: $title-color;
}

a {
  color: $accent-color;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: underline;
  }

  &:visited {
    color: $accent-color;
  }
}

button.link {
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: $accent-color;

  &:hover, &:active {
    text-decoration: underline;
  }
}
