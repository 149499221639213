@import 'configuration';

body.item {
  .watermark-container {
    align-self: flex-end;
  }

  .poster-row {
    display: flex;
    gap: calc($global-padding / 2);

    @media #{$media-mobile} {
      flex-direction: column-reverse;
    }

    .item {
      flex: 1;
    }

    @media #{$media-desktop} {
      .content {
        align-self: flex-end;
      }
      .image {
        align-self: flex-start;
      }
    }

    @media #{$media-mobile} {
      .image {
        align-self: center;
      }
    }
  }

  .poster {
    max-width: 100%;
    max-height: calc(100vh - #{$global-padding * 2});
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: calc($global-padding / 2);
  }

  .purchase {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .label {
      font-size: 1.5em;
      font-weight: 300;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }

    .links {
      display: flex;
      gap: calc($global-padding / 2);
    }
  }
}
