@import 'configuration';

body.home {
  main {
    align-items: center;
    gap: 3.5rem;
    @media #{$media-desktop} {
      align-self: center;
    }

    .home-page-logo-container {
      box-sizing: border-box;
      width: 100%;
      max-width: 800px;

      @media #{$media-desktop} {
        padding-left: $global-padding;
        padding-right: $global-padding;
      }

      svg {
        fill: $font-color;
      }
    }

    .divider {
      border-top: 1px solid $font-color;
      width: 100%;
    }

    .releases {
      display: flex;
      gap: 4em;

      @media #{$media-mobile} {
        flex-direction: column;
        gap: 2em;
      }

      .release {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        max-width: 30rem;

        .heading {
          text-align: center;
          text-transform: uppercase;
          word-spacing: 1em;
          font-family: 'Jost', sans-serif;
          font-size: 2em;
        }

        .poster {
          height: 20rem;
          width: auto;
        }

        h3.title {
          text-align: center;
          font-style: italic;
        }

        .description {
          text-transform: uppercase;
          letter-spacing: 0.2em;
        }

        .content {
          align-self: stretch;
          flex-grow: 1;
        }

        .link {
          background-color: #C7CF98;
          text-transform: uppercase;
          text-align: center;
          padding-top: calc($global-padding / 4);
          padding-bottom: calc($global-padding / 4);
          width: 100%;
        }

        a.link {
          color: inherit;
          text-decoration: underline;

          &:visited {
            color: inherit;
          }
        }
      }
    }
  }
}
