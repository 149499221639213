@import 'configuration';

html {
  height: 100vh;
}

body {
  font-family: 'Jost', sans-serif;
  background-color: $background-color;
  color: $font-color;
  display: flex;
  align-items: flex-start;
  gap: $global-padding;

  @media #{$media-desktop} {
    padding: 0 $global-padding;
  }

  @media #{$media-mobile} {
    padding: 0 calc($global-padding / 2);
  }
}

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: calc($global-padding / 2);

  @media #{$media-desktop} {
    justify-content: space-between;
    min-height: calc(100vh - #{2 * $global-padding});
    padding: $global-padding 0;
  }

  @media #{$media-mobile} {
    flex-direction: column;
    gap: calc($global-padding / 2);
    min-height: calc(100vh - 5rem);
    padding: calc($global-padding / 2) 0;
  }
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: calc($global-padding / 2);

  @media #{$media-desktop} {
    align-self: flex-end;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

h1, h2, h3, h4, h5, h6 {
  text-align: right;
}

p {
  align-self: stretch;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0
}
