@import 'configuration';

$mobile-menu-button-height: 2rem;
$mobile-menu-padding: 1rem;
$mobile-menu-height: 2rem + 2 * $mobile-menu-padding;

.menu {
  flex-shrink: 0;
  text-transform: uppercase;

  @media #{$media-desktop} {
    width: $menu-size;
    padding: $global-padding 0;
    position: sticky;
    top: 0;
  }

  @media #{$media-mobile} {
    display: none;
  }

  button {
    text-transform: uppercase;
  }

  ul {
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    padding: 0;

    ul, li {
      margin-bottom: calc($global-padding / 3);
      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      padding-left: $global-padding;
    }
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $mobile-menu-padding;
  background-color: rgba($background-color, 0.6);

  @media #{$media-desktop} {
    display: none;
  }

  button {
    border: 0;
    padding: 0;
    background: none;
    display: block;

    svg {
      display: block;
      height: $mobile-menu-button-height;
      fill: $font-color;
    }
  }

  .menu {
    display: block;

    ul {
      margin-top: $mobile-menu-padding;
    }

    [data-mobile-menu] {
      display: none;
    }
  }



  &.open {
    background-color: lighten($background-color, 10%);
  }
}

#mobile-menu-publications, #mobile-menu-plays, #desktop-menu-publications, #desktop-menu-plays {
  display: none;
}

main {
  @media #{$media-mobile} {
    padding-top: $mobile-menu-height;
  }
}
