@import 'configuration';

.subscribe {
  color: #FFF;
  background-color: #231F20;

  padding: calc($global-padding / 2);

  justify-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: calc($global-padding / 4);
}
